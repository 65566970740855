import { useState } from "react";
import { jsPDF } from "jspdf";
import ReactDOMServer from "react-dom/server";
import DataContainer from "../../services/DataContainer";
import { AvailableVariations } from "../../pages/Configurator/configurator.model";
import { Props } from "./types";
import { Button } from "../Button/Button";
import { VarianButtonEnum } from "../../enum/Button/variantButton.model";
import { IconModelEnum } from "../../enum/Button/iconButton.model";

import "../../assets/fonts/VolvoNovum/VolvoNovum-Regular-normal";
import "../../assets/fonts/VolvoNovum/VolvoNovum-Medium-normal";
import "../../assets/fonts/NotoSans/NotoSans-Regular-normal";
import "../../assets/fonts/NotoSans/NotoSans-Medium-normal";
import { Disclaimer, MainSettings, MainTopMenus, SummarySettings } from "../../enum/enums";
import { pdfStyle } from "../../enum/pdfStyle";
import { kindOfIconButton } from "../../consts/Button/iconConstButton";
import useGeneratePdf from "../../utils/useGeneratePdf";

const logo = require("../../assets/images/logo.png");
const logoFooter = require("../../assets/images/logoFooter.png");
const year = new Date();


export const PdfDocument = ({ variants, title, interiorImg, exteriorImg, disabledAndLoading }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const translateComponentName = (componentId: any) =>{
    const component = DataContainer.findComponentById(componentId);
    const componentGroup = DataContainer.getComponentGroups().find((cg: any) => cg.id === component.group_id);
    return DataContainer.sectionKey(MainTopMenus.MAIN_TOPMENU, `${componentGroup.name.toLowerCase()}_${component.name.toLowerCase()}_title`).replace(/ß/g, "ss");
  };

  variants = variants.filter((variant) => {    
    const component = DataContainer.findComponentById(variant.component_id);    
    const compTranslation = translateComponentName(component.id);
    return compTranslation 
      && DataContainer.findComponentById(variant.component_id).name !== "Chassi_type"
      && (!compTranslation.startsWith("hidden_") 
      && !compTranslation.startsWith("interior_dashboard_"));
  });

  
  const { filteredVariants, generatingPdf } =  useGeneratePdf(variants);

  const camelize = (str: string, makeFirstUpper: boolean) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) return "";
      return index === 0 && !makeFirstUpper ? match.toLowerCase() : match.toUpperCase();
    });
  };

  const translateVariation = (variation: any) => {
    const component = DataContainer.findComponentById(variation.component_id);
    let sectionKey = `Options.${component.name}`;
    const componentGroup = DataContainer.findComponentGroupByComponentId(variation.component_id);
    if (["interior", "exterior"].includes(componentGroup.name.toLowerCase())){
      if (["Ext_trim_levels", "Int_trim_levels"].includes(component.name)) {
        sectionKey = `${`Options.${componentGroup.name}${camelize(component.name.split("_").slice(1).join("_").replaceAll("_"," "), true)}`}`;
      }
    }
    let variationTranslation = DataContainer.sectionKey(
      sectionKey,`${variation.original_key}_title`) + " " + DataContainer.sectionKey(sectionKey,`${variation.original_key}`);
    if(variationTranslation.includes(variation.original_key)){
      variationTranslation = DataContainer.sectionKey(sectionKey,`${variation.original_key}`);
    }
    return variationTranslation.replace(/ß/g, "ss");
  };

  const pages: { [key: string]: any } = {
    page1: (
      <>
        <div>
          <div style={pdfStyle.headerLogo}>
            <img style={pdfStyle.logo} src={logo} alt=" " />
          </div>
          <div style={pdfStyle.hero}>
            <div style={pdfStyle.heroImageWrap}>
              <img style={pdfStyle.heroImg} src={exteriorImg} alt=" " />
            </div>
            <ul style={pdfStyle.selectedVariants}>
              {filteredVariants?.map((variant: AvailableVariations | any, index: number) => (
                <li key={variant?.id || index} style={pdfStyle.selectedVariantsLi}>
                  <h3 style={pdfStyle.selectedVariantsLiH3}>
                    {translateComponentName(variant?.component_id)}
                  </h3>
                  {translateVariation(variant)}
                </li>
              ))}
            </ul>
          </div>
        </div><div style={pdfStyle.productTitleWrap}>
          <p style={pdfStyle.productTitle}>{title}</p>
        </div><div style={pdfStyle.interior} className="interior">
          <img style={pdfStyle.interiorImg} src={interiorImg} alt="" />
        </div>
      </>),
    page2: (
      <>
        <div style={pdfStyle.categories}>
          <h4 style={pdfStyle.categoriesH4}>{DataContainer?.sectionKey(SummarySettings.SUMMARY, SummarySettings.OVERVIEW_CONFIGURATION)}</h4>
          <div style={pdfStyle.variantsList}>
            {filteredVariants?.filter((v) => v.image)?.slice(0, 9).map((variant: AvailableVariations | any, index: number) => (
              <div key={variant?.id || index} style={pdfStyle.variantItem}>
                <div style={pdfStyle.variantWrap}>
                  <div style={{...pdfStyle.border, borderRadius: variant?.component_id == "1448" ? "50%" : "0"}}>
                    <img style={{...pdfStyle.variantImg, borderRadius: variant?.component_id == "1448" ? "50%" : "0"}} src={variant?.image} alt="" />
                  </div>
                </div>
                <p style={pdfStyle.cat}>{translateComponentName(variant?.component_id)}</p>
                <p style={pdfStyle.subcat}>
                  {translateVariation(variant)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </>
    ),
    page3: (
      <>
        <div style={pdfStyle.categories}>
          <div style={pdfStyle.variantsList}>
            {filteredVariants?.filter((v) => v.image)?.slice(9).map((variant: AvailableVariations | any, index: number) => (
              <div key={variant?.id || index} style={pdfStyle.variantItem}>
                <div style={pdfStyle.variantWrap}>
                  <div style={{...pdfStyle.border, borderRadius: variant?.component_id == "1448" ? "50%" : "0"}}>
                    <img style={{...pdfStyle.variantImg, borderRadius: variant?.component_id == "1448" ? "50%" : "0"}} src={variant?.image} alt="" />
                  </div>
                </div>
                <p style={pdfStyle.cat}>{translateComponentName(variant?.component_id)}</p>
                <p style={pdfStyle.subcat}>
                  {translateVariation(variant)}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div style={{ flex: "1", marginTop: "80px" }}>
          <div style={pdfStyle.summaryDisclaimer}>
            <h4 style={pdfStyle.summaryDisclaimerTitle}>
              {DataContainer?.sectionKey(MainSettings.MAIN_DISCLAIMER, Disclaimer.DISCLAIMER_TITLE)}
            </h4>
            <div style={pdfStyle.summaryDisclaimerText}>
              {DataContainer?.sectionKey(MainSettings.MAIN_DISCLAIMER, Disclaimer.DISCLAIMER_TEXT)}
            </div>
          </div>
          <div style={pdfStyle.summaryCopyright}>
            <img style={pdfStyle.summaryCopyrightImg} src={logoFooter} alt=" " />
            <span style={pdfStyle.summaryCopyrightText}>©{year.getFullYear()} AB Volvo. All Rights Reserved.</span>
          </div>
        </div>
      </>
    )
  };

  const Element = () => (
    Object.keys(pages).map((page: string, index) => (
      <div key={index} style={pdfStyle.container}>
        <div style={pdfStyle.page}>
          {pages[page]}
        </div>
      </div>
    )).reduce((prev, curr) => [prev, "", curr] as any)
  );

  const exportPDF = () => {
    const doc = new jsPDF("p", "pt", "a4");
    doc.getFontList();
    doc.html(ReactDOMServer.renderToString(<Element />), {
      callback: function (doc: any) {
        // For some reason, jspdf is generating one more blank page.
        // todo: make the condition dynamic
        if (doc.getNumberOfPages() === 4) {
          doc.deletePage(4);
        }

        doc.save( `${title}.pdf`, { returnPromise: true }).then(() => {
          setLoading(false);
        });
      },
    });
  };

  const downloadPdf = () => {
    exportPDF();
    setLoading(true);
  };

  return (
    disabledAndLoading || generatingPdf ? (<div className="bg-gray-300 animate-pulse border-none rounded-3xl w-11 h-11 mx-3"><span>{kindOfIconButton[IconModelEnum.PDF]}</span></div>) : (
      <Button
        variant={VarianButtonEnum.SECONDARYICONBUTTON}
        icon={loading ? IconModelEnum.LOADING : IconModelEnum.PDF}
        customClassNames="mx-3"
        onClickEvent={() => {
          downloadPdf();
          DataContainer.sendDataToAdobeDataLayer("truckbuilder_summary_download-configuration", {}, {["downloadFormat"]: "PDF"});
        }}
      />)
  );
};

export default PdfDocument;
