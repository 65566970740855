import { Props } from "./types";
import { SidebarItem } from "../Sidebar-Item/SidebarItem";
import { SidebarProps } from "../Sidebar-Item/types";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import DataContainer from "../../services/DataContainer";
import { MainCategories, MainSettings, MarketSettings } from "../../enum/enums";
import { DataContainerContext } from "../../common/context/DataContainerProvider";
import { useContext, useEffect, useState } from "react";
import { AdobeCampaignService, _allPreviousChoice } from "../../services/adobe/AdobeCampaign";
import { AdobeEventType } from "../../enum/adobe/adobeEventType";

export const List = ({
  items,
  paddingX,
  paddingY,
  className,
  onClickItemEvent,
  border,
  singleItemClassNames,
  hideEmptyImage,
  toggleSidebar,
  pageName
}: Props) => {
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const { currentLocale, activeComponentGroups, modelName} = useContext(DataContainerContext);
  const [currentProduct, setCurrentProduct] = useState<any>([]);
  const adobeService = new AdobeCampaignService();
  const prevActiveComponentGroupsName = adobeService.usePrevious(activeComponentGroups.name);

  useEffect(() => {
    setCurrentProduct(DataContainer.mLoadedData);
  },[DataContainer.mLoadedData]);

  const sidebarItemBottomBorder = (index: number) => {
    if ((pageName === "configurator" && index === items.length) || (pageName !== "configurator" && index === items.length -1)) {
      return false;
    }
    return  true;
  };

  return (
    <ul
      className={classNames(
        "bg-dark-grey",
        { [`px-${paddingX}`]: paddingX, [`py-${paddingY}`]: paddingY },
        className
      )}
    >
      {(location.includes("/configurator") || location.includes("/summary")) && 
      <SidebarItem
        onClickEvent={() => {
          navigate("/"+currentLocale.locale);
        }}
        border={true}
        title={DataContainer.sectionKey(MainSettings.MAIN_CATEGORIES, MainCategories.SEGMENT_CATEGORY)}
        number="01"
        productName={currentProduct && currentProduct?.product_info?.name}
        singleItemClassNames={singleItemClassNames}
        link={""}
        hideEmptyImage={hideEmptyImage}
        pageName={pageName}
      />}
      
      {items?.map((item: SidebarProps, index: number) => (
        <SidebarItem
          onClickEvent={() => {
            onClickItemEvent({name: item.name ? item.name : item.title, id: item.id, index: index, segment: item.link});
            (!location.includes("/configurator") && !location.includes("/summary"))
            && localStorage.setItem("segment", item.link!);
          }}
          key={item.id}
          title={item.name ? DataContainer.sectionKey(MainSettings.MAIN_CATEGORIES, item.name.toLowerCase()) : item.title}
          number={`${index < 10 ? `0${(location.includes("/configurator") || location.includes("/summary")) ? index+2 : index+1}` : index+1}`}
          productName={item.productName}
          border={sidebarItemBottomBorder(index)}
          icon={item.icon}
          imageUrl={item.imageUrl}
          link={item.link}
          hideEmptyImage={hideEmptyImage}
          singleItemClassNames={singleItemClassNames}
          pageName={pageName}
        />
      ))}

      {location.includes("/configurator") &&
      <SidebarItem
        onClickEvent={() => {
          adobeService.sendAnalytics(AdobeEventType.PAGE_ENTERED, {category: prevActiveComponentGroupsName}, _allPreviousChoice);
          navigate(`/${currentLocale.locale}/summary/${modelName}/${DataContainer.generateUniqueHashForSelections()}`);
        }}
        title={DataContainer.sectionKey(MainSettings.MAIN_CATEGORIES,MainCategories.SUMMARY_CATEGORY)}
        number={"0" + (items?.length + 2)}
        link={""}
        singleItemClassNames={singleItemClassNames}
        hideEmptyImage={hideEmptyImage}
        pageName={pageName}
      />}
      
      {location.includes("/summary") &&
      <SidebarItem
        onClickEvent={() => toggleSidebar(false)}
        title={DataContainer.sectionKey(MainSettings.MAIN_CATEGORIES,MainCategories.SUMMARY_CATEGORY)}
        number={"0" + (items?.length + 2)}
        link={""}
        singleItemClassNames={singleItemClassNames}
        hideEmptyImage={hideEmptyImage}
        pageName={pageName}
      />}
    </ul>
  );
};
