import { useEffect, useState } from "react";
import useFirstRender from "./useFirstRender";


function useAppSizeControl(app?: HTMLElement, header?: HTMLElement, headerInitialHeight?: any) {
  const [isHidden, setIsHidden] = useState(true);
  const isFirstRender = useFirstRender();

  useEffect(() => {
    if (!(app && header) || app.getBoundingClientRect().top == 0 && isFirstRender) {
      setIsHidden(false);
      return;
    }

    function handleScroll() {
      if (!(app && header)) { return; }
      const appTop = app?.getBoundingClientRect().top ?? 0;
      if (appTop >= headerInitialHeight) {
        setIsHidden(true);
        return;
      } else if (appTop <= 10) {
        header.style.top = "0";
        header.style.height = "0px";
        header.style.overflow = "hidden";
        app.style.position = "fixed";
        app.style.top = "0";
        app.style.left = "0";
        app.style.width = "100%";
        app.style.height = "100%";
        app.style.bottom = "0";
        app.style.right = "0";
        app.style.zIndex = "1";
        app.style.overflowY = "auto";
        setIsHidden(false);
        return;
      }

      header.style.top = `-${headerInitialHeight - appTop}px`;
    }

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  });


  return [
    isHidden,
    setIsHidden
  ] as const;

}

export default useAppSizeControl;