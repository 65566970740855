import {Props} from "./types";
import {useState} from "react";

export const CustomCheckbox = (
  {
    name,
    label,
    customClassNames,
    customLabelClasses,
    onChangeEvent,
  }: Props
) => {

  return (
    <label className="checkbox relative cursor-pointer text-2xs bg-transparent border-none select-none flex gap-10 h-10 w-full items-center mb-6">
      <input
        name={name}
        type="checkbox"
        className = "cursor-pointer opacity-0 w-0 h-0 pb-4"
        onChange={onChangeEvent}
      />
      <span
        className={`checkmark absolute w-6 h-6 left-0
          bg-transparent border border-black rounded
          after:absolute after:hidden
          after:border-blue after:rotate-45 after:h-4 after:w-2.5 after:left-1.5 after:top-px ${customClassNames}`}
        onChange={onChangeEvent}
      />
      <span className={`${customLabelClasses} flex`}>
        {label}
      </span>
    </label>
  );
};
